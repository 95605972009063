import React, { useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tab,
  Tabs,
  Paper,
  useTheme,
} from '@mui/material';

const staticAcsUrlTabLabels = ["Thermal", "N1", "N2", "N3", "N4", "Nitro"];

const staticAcsUrlData = [
  [
    {name: "Thermal N1 Left", acsUrl: "rtsp://10.1.10.48:554/avc/?cbr=500&quant=75&frate=15"},
    {name: "Thermal N1 Right", acsUrl: "rtsp://10.1.10.49:554/avc/?cbr=500&quant=75&frate=15"},
    {name: "Thermal N2 Left", acsUrl: "rtsp://10.1.10.63:554/avc/?cbr=500&quant=75&frate=15"},
    {name: "Thermal N2 Right", acsUrl: "rtsp://10.1.10.64:554/avc/?cbr=500&quant=75&frate=15"},
    {name: "Thermal N3 Left", acsUrl: "rtsp://10.1.10.78:554/avc/?cbr=500&quant=75&frate=15"},
    {name: "Thermal N3 Right", acsUrl: "rtsp://10.1.10.79:554/avc/?cbr=500&quant=75&frate=15"},
    {name: "Thermal N4 Left", acsUrl: "rtsp://10.1.10.93:554/avc/?cbr=500&quant=75&frate=15"},
    {name: "Thermal N4 Right", acsUrl: "rtsp://10.1.10.94:554/avc/?cbr=500&quant=75&frate=15"},
  ],
  [
    {name: "N1 Up", acsUrl: "http://10.1.10.20:31020/?function=R1_Up"},
    {name: "N1 Down", acsUrl: "http://10.1.10.20:31020/?function=R1_Down"},
    {name: "N1 Left", acsUrl: "http://10.1.10.20:31020/?function=R1_Left"},
    {name: "N1 Right", acsUrl: "http://10.1.10.20:31020/?function=R1_Right"},
    {name: "N1 Blowout", acsUrl: "http://10.1.10.20:31020/?function=R1_Blowout"},
    {name: "N1 Spray On", acsUrl: "http://10.1.10.20:31020/?function=R1_SprayOn"},
    {name: "N1 Spray Off", acsUrl: "http://10.1.10.20:31020/?function=R1_SprayOff"},
  ],
  [
    {name: "N2 Up", acsUrl: "http://10.1.10.20:31020/?function=R2_Up"},
    {name: "N2 Down", acsUrl: "http://10.1.10.20:31020/?function=R2_Down"},
    {name: "N2 Left", acsUrl: "http://10.1.10.20:31020/?function=R2_Left"},
    {name: "N2 Right", acsUrl: "http://10.1.10.20:31020/?function=R2_Right"},
    {name: "N2 Blowout", acsUrl: "http://10.1.10.20:31020/?function=R2_Blowout"},
    {name: "N2 Spray On", acsUrl: "http://10.1.10.20:31020/?function=R2_SprayOn"},
    {name: "N2 Spray Off", acsUrl: "http://10.1.10.20:31020/?function=R2_SprayOff"},
  ],
  [
    {name: "N3 Up", acsUrl: "http://10.1.10.20:31020/?function=R3_Up"},
    {name: "N3 Down", acsUrl: "http://10.1.10.20:31020/?function=R3_Down"},
    {name: "N3 Left", acsUrl: "http://10.1.10.20:31020/?function=R3_Left"},
    {name: "N3 Right", acsUrl: "http://10.1.10.20:31020/?function=R3_Right"},
    {name: "N3 Blowout", acsUrl: "http://10.1.10.20:31020/?function=R3_Blowout"},
    {name: "N3 Spray On", acsUrl: "http://10.1.10.20:31020/?function=R3_SprayOn"},
    {name: "N3 Spray Off", acsUrl: "http://10.1.10.20:31020/?function=R3_SprayOff"},
  ],
  [
    {name: "N4 Up", acsUrl: "http://10.1.10.20:31020/?function=R4_Up"},
    {name: "N4 Down", acsUrl: "http://10.1.10.20:31020/?function=R4_Down"},
    {name: "N4 Left", acsUrl: "http://10.1.10.20:31020/?function=R4_Left"},
    {name: "N4 Right", acsUrl: "http://10.1.10.20:31020/?function=R4_Right"},
    {name: "N4 Blowout", acsUrl: "http://10.1.10.20;31020/?function=R4_Blowout"},
    {name: "N4 Spray On", acsUrl: "http://10.1.10.20:31020/?function=R4_SprayOn"},
    {name: "N4 Spray Off", acsUrl: "http://10.1.10.20:31020/?function=R4_SprayOff"},
  ],
  [
    {name: "Nitro On", acsUrl: "http://10.1.10.20:31020/?function=NitroOn"},
    {name: "Nitro Off", acsUrl: "http://10.1.10.20:31020/?function=NitroOff"},
    {name: "Pressure RLS", acsUrl: "http://10.1.10.20:31020/?function=Press_Rls"},
  ],
];

const acsColumns = [
  { accessorKey: 'name', header: 'Name' },
  { accessorKey: 'acsUrl', header: 'ACS URL' }
];

export default function AcsUrlTable(props) {
  const [tabIndex, setTabIndex] = useState(0);
  const theme = useTheme();

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  return (
    <Box sx={{ 
      width: '100%',
      '&:hover': { backgroundColor: theme.palette.mode === 'light' ? '#F3F3F3' : '#1D1D1D' }
    }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabIndex} onChange={handleTabChange}>
          {staticAcsUrlTabLabels.map((label, index) => (
            <Tab key={index} label={label} />
          ))}
        </Tabs>
      </Box>
      {staticAcsUrlTabLabels.map((label, index) => (
        <TabPanel key={index} value={tabIndex} index={index}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="ACS URL table">
              <TableHead>
                <TableRow>
                  {acsColumns.map((column) => (
                    <TableCell key={column.accessorKey}>{column.header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {staticAcsUrlData[index]?.map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.acsUrl}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
      ))}
    </Box>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

