import React, { useState, useEffect } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, useTheme } from '@mui/material';
import { systemsIntegrationAxios as axios } from "../../../utils/AxiosUtility";

export default function ProductInfoTable({id, type}) {
  const borderStyle = { border: null };
  const externalBorderStyle = {borderTop: '2px solid #BEBEBE', borderRight: '2px solid #BEBEBE', borderBottom: '2px solid #BEBEBE', borderLeft: '2px solid #BEBEBE'};
  const [rowId, setRowId] = useState(id);
  const [isLoading, setIsLoading] = useState(true);
  const [productInfo, setProductInfo] = useState({});
  const [coldWeatherPackage, setColdWeatherPackage] = useState();
  const theme = useTheme();

  const fetchData = async () => {
    try {
      const productInfoEndpoint = `api/ProductSummary/getProductSummaryFromPcId/${id}`;
      const response = await axios.get(productInfoEndpoint);
      setProductInfo(response.data);
      setIsLoading(false);
    }
    catch (error) {
      setIsLoading(false);
      console.error("Error retrieving data", error); 
    }
  };

  const fetchWeatherPackage = async () => {
    try {
      const coldWeatherEndpoint = `api/ProductConfiguration/productConfig/${id}`;
      const response = await axios.get(coldWeatherEndpoint);
      setColdWeatherPackage(JSON.stringify(response.data.coldWeatherPackage));

    }
    catch (error) {
      console.error("Error fetching programmer.");
    }
  };

  useEffect(() => {
    fetchData();
    fetchWeatherPackage();
  }, [rowId]);

  
  return (
    <TableContainer component={Paper}>
      <Table
        style={{ 
          borderCollapse: 'collapse', 
          ...externalBorderStyle,
          minHeight: type === 'ED' ? '510px' : '350px',
          maxHeight: type === 'ED' ? '510px' : '350px',
          overflow: 'auto',
        }} 
        sx={{
          '& .MuiTableCell-root': {
            padding: '4px 75px', 
            fontSize: '0.875rem',
            height: '33px',
            backgroundColor: 'inherit',
          },
          '& .MuiTableCell-head': {
            fontWeight: 'bold',
            backgroundColor: 'inherit',
            height: '65px'
          },
          '& .MuiTableRow-root': {
            height: '24px', 
          },
          '& th, & td': {
            whiteSpace: 'nowrap',
            textAlign: 'center',
          },
          '&:hover': { backgroundColor: theme.palette.mode === 'light' ? '#F3F3F3' : '#252525' }
        }}
      >
        
        <TableHead>
          <TableRow>
            <TableCell colSpan={3} align="center" style={{ ...borderStyle, fontWeight: 'bold' }}>
              <Box display="inline-flex" alignItems="center" justifyContent="space-around" width="auto" marginLeft='15%'>
                <Typography variant='h6' gutterBottom mr={4} style={{ fontWeight: 'bold', fontSize: '27px' }}>
                  Product Information
                </Typography>
              </Box>
            </TableCell>
          </TableRow>
        </TableHead>
        
        <TableBody>
          <TableRow>
            <TableCell style={borderStyle}>Product Name:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}>
                {productInfo.productName}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle}>Product Type:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}>
              {productInfo.productType}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle}>Riser Count:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}>
              {productInfo.riserCount}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle}>Thermal Count:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}>
              {productInfo.thermalCameraCount}
              </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle}>Flame Count:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}>
              {productInfo.flameDetectorCount}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle}>Smoke Count:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}>
              {productInfo.smokeDetectorCount}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle}>Cold Weather Package:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}>
              {coldWeatherPackage}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

