import React, {useState, useEffect} from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper, useTheme } from '@mui/material';
import { systemsIntegrationAxios as axios } from "../../../utils/AxiosUtility";
import EditSiteInfo from './EditSiteInfo';

export default function SiteInfoTable({id}) {
  const borderStyle = { border: null };
  const externalBorderStyle = {borderTop: '2px solid #BEBEBE', borderRight: '2px solid #BEBEBE', borderBottom: '2px solid #BEBEBE', borderLeft: '2px solid #BEBEBE'};
  const [siteInfoData, setSiteInfoData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return ''; 
    const cleaned = ('' + phoneNumber).replace(/\D/g, ''); 
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`; 
    }
    return phoneNumber; 
  };

  const onEdit = () => {
    fetchData();
  }

  const fetchData = () => {
    const endpoint = `api/ProductSummary/getProductSummaryFromPcId/${id}`;

    axios.get(endpoint)
        .then((response) => {
            setSiteInfoData(response.data);
            setIsLoading(false);
        })
        .catch((error) => {
            setIsLoading(false);
            console.error(error);
            if (error.message) {
                alert(error.message);
            } else {
                alert("Error fetching Site info.");
            }
        });
  }

  useEffect(() => {
    fetchData();
  }, [id]);
    
  return (
    <TableContainer component={Paper}>
      <Table
        style={{ borderCollapse: 'collapse', ...externalBorderStyle, minHeight: '500px', maxHeight: '500px' }} 
        sx={{
          '& .MuiTableCell-root': {
            padding: '4px', 
            fontSize: '0.75rem',
            height: '36px',
            backgroundColor: 'inherit'
          },
          '& .MuiTableCell-head': {
            fontWeight: 'bold',
            backgroundColor: 'inherit',
            height: '65px'
          },
          '& .MuiTableRow-root': {
            height: '33px', 
          },
          '& th, & td': {
            whiteSpace: 'nowrap', 
            textAlign: 'center', 
          },
          '&:hover': { backgroundColor: theme.palette.mode === 'light' ? '#F3F3F3' : '#252525' }
        }}
      >
        
        <TableHead>
          <TableRow>
            <TableCell colSpan={3} align="center" style={{ ...borderStyle, fontWeight: 'bold' }}>
              <Box display="inline-flex" alignItems="center" justifyContent="space-around" width="auto">
              <Typography variant='h6' gutterBottom ml={12} style={{ fontWeight: 'bold', fontSize: '27px' }}>
                  Site Information
                </Typography>
                {!isLoading && siteInfoData && (
                  <Box ml={6}>
                    <EditSiteInfo pcId={id} siteInfo={siteInfoData} onSave={fetchData} />
                  </Box>
                )}
              </Box>
            </TableCell>
          </TableRow>
        </TableHead>
        
        <TableBody>
          <TableRow>
            <TableCell style={borderStyle}>Customer Name:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}>{siteInfoData.customerMaster}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle}>Site Name:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}>{siteInfoData.siteName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle}>Account Manager:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}>{siteInfoData.csAccountManager}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle}></TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}></TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle}>Site Address:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}>{siteInfoData.siteAddress}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle}>Site Contact:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}>{siteInfoData.siteContact}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle}>Site Contact Phone:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}>{formatPhoneNumber(siteInfoData.siteContactPhone)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle}></TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}></TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle}>IT Contact:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}>{siteInfoData.itContact}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle}>IT Contact Phone:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}>{formatPhoneNumber(siteInfoData.itContactPhone)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

