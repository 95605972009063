import React, { useState, useEffect } from "react";
import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Typography, Select, Snackbar, CircularProgress, TextField, Grid } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { systemsIntegrationAxios as axios } from "../../../utils/AxiosUtility";

export default function EditNetworkInfo({ netInfo, onSave }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSnackbarClose = () => setSnackbarOpen(false);

  const [wanIpAddress, setWanIpAddress] = useState("");
  const [wanSubnetMask, setWanSubnetMask] = useState("");
  const [wanGateway, setWanGateway] = useState("");
  const [wanDns1, setWanDns1] = useState("");
  const [wanDns2, setWanDns2] = useState("");
  const [cellIpAddress, setCellIpAddress] = useState("");
  const [cellSubnetMask, setCellSubnetMask] = useState("");
  const [cellGateway, setCellGateway] = useState("");
  const [cellDns1, setCellDns1] = useState("");
  const [cellDns2, setCellDns2] = useState("");
  const [sim1Brand, setSim1Brand] = useState("");
  const [sim1Id, setSim1Id] = useState("");
  const [simIp, setSimIp] = useState("");
  const [sim2Brand, setSim2Brand] = useState("");
  const [sim2Id, setSim2Id] = useState("");
  const [sim2Ip, setSim2Ip] = useState("");
  const [router, setRouter] = useState("");
  const [ddns, setDdns] = useState("");
  const [imei, setImei] = useState("");
  const [networkInfo, setNetworkInfo] = useState();

  const saveNetworkInfo = () => {
    const newNetworkInfo = {
      networkId: netInfo.networkId,
      productConfigId: netInfo.productConfigId,
      wanIpAddress,
      wanSubnetMask,
      wanGateway,
      wanDns1,
      wanDns2,
      cellIpAddress,
      cellSubnetMask,
      cellGateway,
      cellDns1,
      cellDns2,
      sim1Brand,
      sim1Id,
      simIp,
      sim2Brand,
      sim2Id,
      sim2Ip,
      router,
      ddns,
      imei
    };

    setLoading(true);

    axios
      .put("api/NetworkInfo/updateNetworkInfo", newNetworkInfo)
      .then(() => {
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error(error);
        alert(error.response?.data || "Error saving network info. Please try again.");
      })
      .finally(() => {
        setLoading(false);
        onSave();
        handleClose();
      });
  };

  useEffect(() => {
    setNetworkInfo(netInfo);
  }, [netInfo]);

  useEffect(() => {
    if (networkInfo) {
      setWanIpAddress(networkInfo.wanIpAddress || "");
      setWanSubnetMask(networkInfo.wanSubnetMask || "");
      setWanGateway(networkInfo.wanGateway || "");
      setWanDns1(networkInfo.wanDns1 || "");
      setWanDns2(networkInfo.wanDns2 || "");
      setCellIpAddress(networkInfo.cellIpAddress || "");
      setCellSubnetMask(networkInfo.cellSubnetMask || "");
      setCellGateway(networkInfo.cellGateway || "");
      setCellDns1(networkInfo.cellDns1 || "");
      setCellDns2(networkInfo.cellDns2 || "");
      setSim1Brand(networkInfo.sim1Brand || "");
      setSim1Id(networkInfo.sim1Id || "");
      setSimIp(networkInfo.simIp || "");
      setSim2Brand(networkInfo.sim2Brand || "");
      setSim2Id(networkInfo.sim2Id || "");
      setSim2Ip(networkInfo.sim2Ip || "");
      setRouter(networkInfo.router || "");
      setDdns(networkInfo.ddns || "");
      setImei(networkInfo.imei || "");
    }
  }, [networkInfo]);

  const gridItemProps = {
    xs: 6,
    container: true,
    justifyContent: "center",
  };

  return (
    <>
      <Button variant="contained" color="primary" style={{ display: 'table-header-group', marginLeft: '10%', marginTop: '15%', marginBottom: '15%' }} onClick={handleOpen}>Edit</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            height: "80vh",
            maxHeight: '575px',
            overflowY: "auto",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Button
            variant="outlined"
            onClick={handleClose}
            style={{ position: "absolute", top: "10px", right: "10px" }}
            disabled={loading}
          >
            X
          </Button>

          <Typography
            variant="h6"
            component="h2"
            style={{ paddingBottom: "20px" }}
          >
            Edit Network Info
          </Typography>

          <Grid
            container
            rowSpacing={0}
            style={{ padding: "30px" }}
          >
            
            <Grid item {...gridItemProps}>
              <TextField
                label="WAN IP Address"
                value={wanIpAddress}
                onChange={(e) => setWanIpAddress(e.target.value)}
                style={{ width: "100%", marginBottom: "20px", paddingRight: "5px" }}
              />
            </Grid>
            <Grid item {...gridItemProps}>
              <TextField
                label="WAN Subnet Mask"
                value={wanSubnetMask}
                onChange={(e) => setWanSubnetMask(e.target.value)}
                style={{ width: "100%", marginBottom: "20px" }}
              />
            </Grid>
            <Grid item {...gridItemProps}>
              <TextField
                label="WAN Gateway"
                value={wanGateway}
                onChange={(e) => setWanGateway(e.target.value)}
                style={{ width: "100%", marginBottom: "20px", paddingRight: "5px" }}
              />
            </Grid>
            <Grid item {...gridItemProps}>
              <TextField
                label="WAN Dns 1"
                value={wanDns1}
                onChange={(e) => setWanDns1(e.target.value)}
                style={{ width: "100%", marginBottom: "20px" }}
              />
            </Grid>
            <Grid item {...gridItemProps}>
              <TextField
                label="WAN Dns 2"
                value={wanDns2}
                onChange={(e) => setWanDns2(e.target.value)}
                style={{ width: "100%", marginBottom: "20px" }}
              />
            </Grid>

            <Grid item {...gridItemProps}>
              <TextField
                label="Cellular IP Address"
                value={cellIpAddress}
                onChange={(e) => setCellIpAddress(e.target.value)}
                style={{ width: "100%", marginBottom: "20px", paddingRight: "5px" }}
              />
            </Grid>
            <Grid item {...gridItemProps}>
              <TextField
                label="Cellular Subnet Mask"
                value={cellSubnetMask}
                onChange={(e) => setCellSubnetMask(e.target.value)}
                style={{ width: "100%", marginBottom: "20px" }}
              />
            </Grid>
            <Grid item {...gridItemProps}>
              <TextField
                label="Cellular Gateway"
                value={cellGateway}
                onChange={(e) => setCellGateway(e.target.value)}
                style={{ width: "100%", marginBottom: "20px", paddingRight: "5px" }}
              />
            </Grid>
            <Grid item {...gridItemProps}>
              <TextField
                label="Cellular Dns 1"
                value={cellDns1}
                onChange={(e) => setCellDns1(e.target.value)}
                style={{ width: "100%", marginBottom: "20px" }}
              />
            </Grid>
            <Grid item {...gridItemProps}>
              <TextField
                label="Cellular Dns 2"
                value={cellDns2}
                onChange={(e) => setCellDns2(e.target.value)}
                style={{ width: "100%", marginBottom: "20px" }}
              />
            </Grid>

            <Grid item {...gridItemProps}>
              <TextField
                label="SIM 1 Brand"
                value={sim1Brand}
                onChange={(e) => setSim1Brand(e.target.value)}
                style={{ width: "100%", marginBottom: "20px", paddingRight: "5px" }}
              />
            </Grid>
            <Grid item {...gridItemProps}>
              <TextField
                label="SIM 1 ID"
                value={sim1Id}
                onChange={(e) => setSim1Id(e.target.value)}
                style={{ width: "100%", marginBottom: "20px" }}
              />
            </Grid>
            <Grid item {...gridItemProps}>
              <TextField
                label="SIM 1 IP"
                value={simIp}
                onChange={(e) => setSimIp(e.target.value)}
                style={{ width: "100%", marginBottom: "20px", paddingRight: "5px" }}
              />
            </Grid>
            <Grid item {...gridItemProps}>
              <TextField
                label="SIM 2 Brand"
                value={sim2Brand}
                onChange={(e) => setSim2Brand(e.target.value)}
                style={{ width: "100%", marginBottom: "20px" }}
              />
            </Grid>
            <Grid item {...gridItemProps}>
              <TextField
                label="SIM 2 ID"
                value={sim2Id}
                onChange={(e) => setSim2Id(e.target.value)}
                style={{ width: "100%", marginBottom: "20px", paddingRight: "5px" }}
              />
            </Grid>
            <Grid item {...gridItemProps}>
              <TextField
                label="SIM 2 IP"
                value={sim2Ip}
                onChange={(e) => setSim2Ip(e.target.value)}
                style={{ width: "100%", marginBottom: "20px" }}
              />
            </Grid>

            <Grid item {...gridItemProps}>
              <TextField
                label="Router"
                value={router}
                onChange={(e) => setRouter(e.target.value)}
                style={{ width: "100%", marginBottom: "20px", paddingRight: "5px" }}
              />
            </Grid>
            <Grid item {...gridItemProps}>
              <TextField
                label="DDNS"
                value={ddns}
                onChange={(e) => setDdns(e.target.value)}
                style={{ width: "100%", marginBottom: "20px", paddingRight: "5px" }}
              />
            </Grid>
            <Grid item {...gridItemProps}>
              <TextField
                label="IMEI"
                value={imei}
                onChange={(e) => setImei(e.target.value)}
                style={{ width: "100%", marginBottom: "20px", paddingRight: "5px" }}
              />
            </Grid>
          </Grid>

          <div
            style={{ position: "absolute", right: "10px" }}
          >
            {loading && <CircularProgress />}

            <Button
              style={{ color: "green" }}
              onClick={saveNetworkInfo}
              disabled={loading}
            >
              Save
            </Button>
          </div>
        </Box>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Network Info updated!
        </MuiAlert>
      </Snackbar>
    </>
  );
}



