import React, { useState, useEffect } from "react";
import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Typography, Select, Snackbar, CircularProgress, TextField, Grid } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { systemsIntegrationAxios as axios } from "../../../utils/AxiosUtility";

export default function EditInternalInfo({internInfo, onSaveProgrammer, onSaveData, prog}) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // eslint-disable-next-line
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSnackbarClose = () => setSnackbarOpen(false);

  const [programmer, setProgrammer] = useState(prog ? prog : "");
  const [programmerOptions, setProgrammerOptions] = useState(['Adam Derose', 'Kim Kluse', 'Sean McGill']);
  const [internalInfo, setInternalInfo] = useState({});
  const [timerTest, setTimerTest] = useState("");

  const saveInternalInfo = () => {
    const newInternalInfo = {
        productConfigurationId: internInfo.productConfigurationId,
        programmer: programmer,
        timerTest: timerTest,
      };

    setLoading(true);

    axios
      .put("api/InternalInfo/updateInternalInfo", newInternalInfo)
      .then(() => {
        setSnackbarOpen(true);
        onSaveProgrammer();
        onSaveData();
      })
      .catch((error) => {
        console.error(error);
        alert(error.response?.data || "Error saving internal info. Please try again.");
      })
      .finally(() => {
        setLoading(false);
        handleClose();
      });
  }

  useEffect(() => {
    setInternalInfo(internInfo);
  }, [internInfo]);

  useEffect(() => {
    if (internalInfo) {
        setProgrammer(prog || "");
    }
  }, [internalInfo]);

  const gridItemProps = {
    xs: 6,
    container: true,
    justifyContent: "center",
  };

  return (
    <>
      <Button 
        variant="contained" 
        color="primary" 
        style={{display: 'table-header-group', marginLeft: 'auto', marginTop: '15%', marginBottom: '15%'}} 
        onClick={handleOpen}
      >
        Edit
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            height: "80vh",
            maxHeight: '300px',
            overflowY: "auto",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Button
            variant="outlined"
            onClick={handleClose}
            style={{ position: "absolute", top: "10px", right: "10px" }}
            disabled={loading}
          >
            X
          </Button>

          <Typography
            variant="h6"
            component="h2"
            style={{ paddingBottom: "20px" }}
          >
            Edit Internal Info
          </Typography>

          <Grid
              container
              rowSpacing={0}
              style={{ padding: "30px" }}
            >
              <Grid item {...gridItemProps}>
                <FormControl fullWidth style={{ marginBottom: '20px', marginRight: '5%' }}>
                  <InputLabel id="programmer-label">Programmer</InputLabel>
                  <Select
                    labelId="programmer-label"
                    value={programmer}
                    onChange={(e) => setProgrammer(e.target.value)}
                    label="Programmer"
                  >
                    {programmerOptions.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* <Grid item {...gridItemProps}>
                <TextField
                  fullWidth
                  label="Timer Test"
                  value={timerTest}
                  onChange={(e) => setTimerTest(e.target.value)}
                  style={{ marginBottom: "20px", marginLeft: '5%' }}
                />
              </Grid> */}
          </Grid>

          <div
            style={{ position: "absolute", right: "10px" }}
          >
            {loading && <CircularProgress />}

            <Button
              style={{ color: "green" }}
              onClick={saveInternalInfo}
              disabled={loading}
            >
              Save
            </Button>
          </div>
        </Box>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Internal Info updated!
        </MuiAlert>
      </Snackbar>
    </>
  );
}

