import React, {useEffect, useState} from 'react';
import { Grid, Box } from '@mui/material';
import AcsUrlTable from './AcsUrl';
import SwitchMapTable from './SwitchMap';

export default function BtmDeviceSection({id, prodConfigId, prodType, unit}) {
    const [unitData, setUnitData] = useState();
    const [thermalCount, setThermalCount] = useState(0);
    const [flameCount, setFlameCount] = useState(0);
    const [smokeCount, setSmokeCount] = useState(0);
    const [productConfigId, setProductConfigId] = useState(prodConfigId);
    const [productType, setProductType] = useState(prodType);
    const [nozzleMasterData, setNozzleMasterData] = useState({});
    const [colorMode, setColorMode] = useState(() => {
        const storedColorMode = localStorage.getItem('colorMode');
        return storedColorMode || 'light';
    });
        
    useEffect(() => {
        setProductType(prodType);
        setProductConfigId(prodConfigId);
        setUnitData(unit);
    }, [id, prodConfigId, prodType]);

    useEffect(() => {
        const storedColorMode = localStorage.getItem("colorMode");
        
        if (storedColorMode) {
            setColorMode(storedColorMode);
        }
    }, [localStorage.getItem('colorMode')]);

    useEffect(() => {
        if (unit) {
            setThermalCount(unit.thermalCameraCount);
            setFlameCount(unit.flameDetectorCount);
            setSmokeCount(unit.smokeDetectorCount);
        };
    }, [unitData]);

    // BSU template render function:
    function RenderBSU() {
        return <AcsUrlTable productType={prodType} prodConfigId={productConfigId} data={nozzleMasterData} />;
    }

    // Continuous Flow template render function:
    function RenderCF() {

        return <AcsUrlTable prodConfigId={productConfigId} data={nozzleMasterData} />
    }

    // On Watch template render function:
    function RenderOW() {
        return (
            <div style={{marginLeft: "0%", width: '100%'}}>
                <Box bgcolor={colorMode === 'dark' ? '#4f4f4f' : '#D0D0D0'} p={5} pt={2} mb={8} style={{minHeight: '800px', maxHeight: '800px'}}>
                    <h3 style={{color: colorMode === 'dark' ? 'white' : 'black'}}>Switch Map</h3>
                    <SwitchMapTable productType={prodType} thermal={parseInt(thermalCount)} flame={parseInt(flameCount)} smoke={parseInt(smokeCount)} />
                </Box>
            </div>
        )
    }

    // Detection Only template render function:
    function RenderED() {
        return (
            <div style={{marginLeft: "0%", width: '100%'}}>
                <Box bgcolor={colorMode === 'dark' ? '#4f4f4f' : '#D0D0D0'} p={5} pt={2} mb={8} style={{minHeight: '800px', maxHeight: '800px'}}>
                    <h3 style={{color: colorMode === 'dark' ? 'white' : 'black'}}>Switch Map</h3>
                    <SwitchMapTable productType={prodType} thermal={parseInt(thermalCount)} flame={parseInt(flameCount)} smoke={parseInt(smokeCount)} />
                </Box>
            </div>
        )
    }

    const renderContent = () => {
        switch (productType) {
            case 'BSU':
                return RenderBSU();
            case 'CF':
                return RenderCF();
            case 'OW':
                return RenderOW();
            case 'ED':
                return RenderED();
        }
    }

    return (
        <Grid item xs={12} sx={{ marginLeft: 'auto', marginRight: 'auto', mt: '-3%', pb: '1%', paddingLeft: 2 }}>
            <Box sx={{ padding: 2 }}>
                {renderContent()}
            </Box>
        </Grid>
    )
};

