import React, { useEffect, useState } from "react";
import { Box, Button, Modal, Typography, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { systemsIntegrationAxios as axios } from "../../../utils/AxiosUtility";

export default function DeleteNozzleTab({ nozzleIds, tabIndex, onTabDeleted, onUpdateTabIndex, nozzleTabName, modalClose }) {
    const [modalOpen, setModalOpen] = useState(false);
    const [nozzleTabId, setNozzleTabId] = useState();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const handleSnackbarClose = () => setSnackbarOpen(false);
    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => {
        setModalOpen(false);
        modalClose();
    };

    useEffect(() => {
        setNozzleTabId(nozzleIds[tabIndex - 1]);
    }, [nozzleIds, tabIndex]);

    const handleFormSubmit = async () => {
        try {
            const endpoint = `api/NozzleMaster/deleteNozzleMaster/${nozzleTabId}`;
            await axios.delete(endpoint);
            onTabDeleted();
            setSnackbarMessage('Selected Tab Deleted!');
            setSnackbarOpen(true);
            handleModalClose();
            onUpdateTabIndex();
        } catch (error) {
            alert(`Error deleting the selected tab. \nPlease refresh and try again.`);
        }
    };

    return (
        <>
            <Button variant="contained" color="primary" onClick={handleModalOpen}>
                Delete Riser
            </Button>
            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 600,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4
                }}>
                    <Button variant="outlined" onClick={handleModalClose} sx={{ position: 'absolute', top: '10px', right: '10px' }}>X</Button>
                    
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 2 }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Delete Riser - "{nozzleTabName}"?
                        </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                        <Typography>
                            Doing so will also delete any device configurations that are in the selected tab.
                        </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 6, mr: 5 }}>
                        <Button variant="contained" color="primary" onClick={handleFormSubmit}>
                            Delete Riser
                        </Button>
                    </Box>
                </Box>
            </Modal>
            
            <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
                <MuiAlert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </>
    );
}


