import React, { useEffect, useState } from 'react';
import { Box, IconButton, Modal, Snackbar, TextField, Button, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { systemsIntegrationAxios as axios } from "../../../utils/AxiosUtility";
import MuiAlert from "@mui/material/Alert";

export default function AddNozzleMaster({ pcId, onAddNozzle, onSave }) {
  const [newNozzleName, setNewNozzleName] = useState('');
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [newNozzleData, setNewNozzleData] = useState();
  const handleSnackbarClose = () => setSnackbarOpen(false);

  const handleAddNozzle = () => {
    if (newNozzleName) {
      onAddNozzle(newNozzleName.toUpperCase());
      setNewNozzleName('');
      setIsModalOpen(false);
      saveNewNozzleMaster();
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const saveNewNozzleMaster = () => {
    const newNozzleData = {
        nozzleId: 0,
        productConfigurationId: pcId,
        nozzleArrangementName: newNozzleName.toUpperCase()
      };

    setLoading(true);

    axios
      .post("api/NozzleMaster/saveNewNozzleMaster", newNozzleData)
      .then(() => {
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error(error);
        alert(error.response?.data || "Error saving airgas data. Please try again.");
      })
      .finally(() => {
        setLoading(false);
        onSave();
        handleCloseModal();
      });
  }

  useEffect(() => {
    if (newNozzleData) {
        setNewNozzleName(newNozzleData.nozzleArrangementName || "");
    }
  }, [newNozzleData]);

  return (
    <>
      <IconButton color="primary" aria-label="add tab" onClick={handleOpenModal}>
        <AddCircleOutlineIcon />
      </IconButton>
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="add-nozzle-modal"
        aria-describedby="add-nozzle-modal-description"
      >
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
          <Button
            variant="outlined"
            onClick={handleCloseModal}
            style={{ position: "absolute", top: "10px", right: "10px" }}
            disabled={loading}
          >
            X
          </Button>
          <Typography sx={{marginBottom: '30px'}} variant="h6" component="h2">
            Add New Nozzle
          </Typography>
          <TextField
            label="Nozzle Name"
            value={newNozzleName}
            sx={{marginBottom: '30px'}}
            onChange={(e) => setNewNozzleName(e.target.value.toUpperCase())}
            fullWidth
          />
          <Button variant="contained" color="primary" onClick={handleAddNozzle}>
            Add
          </Button>
        </Box>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          New Nozzle added!
        </MuiAlert>
      </Snackbar>
    </>
  );
}


