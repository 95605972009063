import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from "react-router-dom";
import { Authenticated } from '../AuthenticatedComponent';

export default function NavMenu() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const timeSheetRoles = ["Service_TimeSheet_Manager", "Service_TimeSheet_User", "SI_TimeSheet_Manager", "SI_TimeSheet_User", "Payroll_Processor"];

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleRedirect = (link) => {
    navigate(`/${link}`);
    setOpen(false);
  }

  return (
    <div>
      <Button style={{color: 'white'}} onClick={toggleDrawer(true)}><MenuIcon /></Button>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <Button onClick={() => handleRedirect('projectManagement')} variant='contained' style={{marginTop: '40px'}}>Project Management</Button>
          <Button onClick={() => handleRedirect('Manufacturing')} variant='contained' style={{marginTop: '20px'}}>Manufacturing</Button>
          <Button onClick={() => handleRedirect('BayAssignment')} variant='contained' style={{marginTop: '20px'}}>Bay Assignment</Button>
          {/* <Button onClick={() => handleRedirect('Site')} variant='contained' style={{marginTop: '20px'}}>Site Lookup</Button> */}
          <Button onClick={() => handleRedirect('Device')} variant='contained' style={{marginTop: '20px'}}>Device Lookup</Button>
          <Button onClick={() => handleRedirect('WIP')} variant='contained' style={{marginTop: '20px'}}>WIP</Button>
          {Authenticated(timeSheetRoles) ? (
            <Button onClick={() => handleRedirect('TimeSheet')} variant='contained' style={{marginTop: '20px'}}>Time Sheet</Button>
          ) : (
            null
          )}
        </div>
      </Drawer>
    </div>
  );
}