import React, { useEffect, useState } from "react";
import { Box, Button, Modal, Typography, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { systemsIntegrationAxios as axios } from "../../../utils/AxiosUtility";

export default function DeleteDevice({ devId, nozId, onDeviceDeleted, onNozzleDeleted, reset, selectedRow }) {
    const [modalOpen, setModalOpen] = useState(false);
    const [dpId, setDpId] = useState();
    const [npId, setNpId] = useState();
    const [rowName, setRowName] = useState();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const handleSnackbarClose = () => setSnackbarOpen(false);
    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => {
        setModalOpen(false);
        reset();
    };

    useEffect(() => {
        setDpId(devId);
        setNpId(nozId);
        setRowName(JSON.stringify(selectedRow.deviceName));
    }, [devId, nozId]);

    const handleFormSubmit = async () => {
        try {
            if (dpId !== null) {
                const endpoint = `api/DeviceInfo/deleteGeneralDeviceConfig/${dpId}`;
                await axios.delete(endpoint);
                onDeviceDeleted();
                setSnackbarMessage('Device Deleted!');
            } else {
                const endpoint = `api/NozzleProgramming/deleteNozzleDeviceConfig/${npId}`;
                await axios.delete(endpoint);
                onNozzleDeleted();
                setSnackbarMessage('Device Deleted!');
            }
            setSnackbarOpen(true);
            handleModalClose();
        } catch (error) {
            alert(`Error deleting the selected device. \nPlease refresh and try again.`);
        }
    };

    return (
        <>
            <Button variant="contained" color="primary" onClick={handleModalOpen}>
                Delete Device
            </Button>
            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 600,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4
                }}>
                    <Button variant="outlined" onClick={handleModalClose} sx={{ position: 'absolute', top: '10px', right: '10px' }}>X</Button>
                    
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 2, ml: 8 }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Delete Device - {rowName}
                        </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                        <Typography>
                            Are you sure you want to delete the selected device?
                        </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 6, mr: 5 }}>
                        <Button variant="contained" color="primary" onClick={handleFormSubmit}>
                            Delete
                        </Button>
                    </Box>
                </Box>
            </Modal>
            
            <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
                <MuiAlert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </>
    );
}


