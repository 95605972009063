import React, { useState, useEffect } from "react";
import { Box, Button, Modal, Typography, Snackbar, CircularProgress, TextField, Grid } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { systemsIntegrationAxios as axios } from "../../../utils/AxiosUtility";

export default function AddAirgas({pcId, onSave}) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // eslint-disable-next-line
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSnackbarClose = () => setSnackbarOpen(false);

  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [st, setSt] = useState("");
  const [country, setCountry] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [airgasData, setAirgasData] = useState();

  const formatPhoneNumber = (value) => {
    if (!value) return value;

    const phoneNumber = value.replace(/[^\d]/g, ""); // Remove all non-digit characters

    if (phoneNumber.length < 4) return phoneNumber;

    if (phoneNumber.length < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const handlePhoneChange = (event) => {
    const formattedPhoneNumber = formatPhoneNumber(event.target.value);
    setPhoneNo(formattedPhoneNumber);
  };

  const saveAirGasData = () => {
    const newAirgasData = {
        productConfigurationId: pcId,
        address1: address1,
        address2: address2,
        city: city,
        st: st,
        country: country,
        phoneNo: phoneNo,
        accountNo: accountNo,
      };

    setLoading(true);

    axios
      .post("api/AirGasSupplier/createAirgas", newAirgasData)
      .then(() => {
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error(error);
        alert(error.response?.data || "Error saving airgas data. Please try again.");
      })
      .finally(() => {
        setLoading(false);
        onSave();
        handleClose();
      });
  }

  useEffect(() => {
    setAirgasData(airgasData);
  }, [airgasData]);

  useEffect(() => {
    if (airgasData) {
      setAddress1(airgasData.address1 || "");
      setAddress2(airgasData.address2 || "");
      setCity(airgasData.city || "");
      setSt(airgasData.st || "");
      setCountry(airgasData.country || "");
      setPhoneNo(airgasData.phoneNo || "");
      setAccountNo(airgasData.accountNo || "");
    }
  }, [airgasData]);

  const gridItemProps = {
    xs: 6,
    container: true,
    justifyContent: "center",
  };

  return (
    <>
      <Button variant="contained" color="primary" style={{display: 'table-header-group', marginLeft: '10%', marginTop: '15%', marginBottom: '15%'}} onClick={handleOpen}>Add</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            height: "80vh",
            maxHeight: '525px',
            minHeight: '525px',
            overflowY: "auto",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Button
            variant="outlined"
            onClick={handleClose}
            style={{ position: "absolute", top: "10px", right: "10px" }}
            disabled={loading}
          >
            X
          </Button>

          <Typography
            variant="h6"
            component="h2"
            style={{ paddingBottom: "20px" }}
          >
            Add Airgas Data
          </Typography>

          <Grid
            container
            rowSpacing={0}
            style={{ padding: "30px" }}
          >

            <Grid item {...gridItemProps}>
              <TextField
                label="Account"
                value={accountNo}
                onChange={(e) => setAccountNo(e.target.value)}
                style={{ width: "100%", marginBottom: "20px" }}
              />
            </Grid>

            <Grid item {...gridItemProps}>
              <TextField
                label="Address1"
                value={address1}
                onChange={(e) => setAddress1(e.target.value)}
                style={{ width: "100%", marginBottom: "20px", paddingRight: "5px" }}
              />
            </Grid>
            
            <Grid item {...gridItemProps}>
              <TextField
                label="Address2"
                value={address2}
                onChange={(e) => setAddress2(e.target.value)}
                style={{ width: "100%", marginBottom: "20px" }}
              />
            </Grid>

            <Grid item {...gridItemProps}>
              <TextField
                label="City"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                style={{ width: "100%", marginBottom: "20px", paddingRight: "5px" }}
              />
            </Grid>

            <Grid item {...gridItemProps}>
              <TextField
                label="State"
                value={st}
                onChange={(e) => setSt(e.target.value)}
                style={{ width: "100%", marginBottom: "20px" }}
              />
            </Grid>

            <Grid item {...gridItemProps}>
              <TextField
                label="Country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                style={{ width: "100%", marginBottom: "20px", paddingRight: "5px" }}
              />
            </Grid>

            <Grid item {...gridItemProps}>
              <TextField
                label="Phone No."
                value={phoneNo}
                onChange={handlePhoneChange}
                style={{ width: "100%", marginBottom: "20px" }}
              />
            </Grid>
          </Grid>

          <div
            style={{ position: "absolute", right: "10px" }}
          >
            {loading && <CircularProgress />}

            <Button
              style={{ color: "green" }}
              onClick={saveAirGasData}
              disabled={loading}
            >
              Save
            </Button>
          </div>
        </Box>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Airgas Data added!
        </MuiAlert>
      </Snackbar>
    </>
  );
}

