import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Avatar, Box, Tooltip, Skeleton, Modal, Fade, Snackbar, useTheme } from '@mui/material';
import frdarklogo from "../../../res/FRLogo.png";
import { systemsIntegrationAxios as axios } from "../../../utils/AxiosUtility";
import MuiAlert from '@mui/material/Alert';
import AddServiceLogNote from './AddServiceLogNote';
import EditServiceLogNote from './EditServiceLogNote';

export default function ServiceLogTable({ id }) {
    const [serviceLogData, setServiceLogData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [noteToEdit, setNoteToEdit] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const handleSnackbarClose = () => setSnackbarOpen(false);
    const theme = useTheme();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        const endpoint = `api/ServiceLog/siteLevelServiceLog/${id}`;

        axios.get(endpoint)
            .then((response) => {
                const sortedData = response.data.sort((a, b) => new Date(b.logDate) - new Date(a.logDate));
                setServiceLogData(sortedData);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                if (error.message) {
                    alert(error.message);
                } else {
                    alert("Error fetching Service log.");
                }
            });
    }

    const handleRowDoubleClick = (note) => {
        setNoteToEdit(note);
        setIsEditModalOpen(true);
    }

    const handleCloseEditModal = () => {
        setIsEditModalOpen(false);
        fetchData();
    }

    const openSnackbar = () => {
        setSnackbarOpen(true);
    }

    const offsetDate = (date) => {
        return date ? new Date(date.getTime() - (date.getTimezoneOffset() * 60000)) : null;
    }

    return (
        <Box
            sx={{
                width: '100%',
                minHeight: '500px',
                maxHeight: '500px', 
                height: '400px',
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'hidden',
                borderLeft: '4px solid #ccc',
                borderRight: '4px solid #ccc',
                marginLeft: 0,
            }}
        >
            <Box 
                display="flex" 
                alignItems="center" 
                justifyContent="space-between" 
                width="100%"
                padding="8px 16px"
            >
                <Typography variant='h6' gutterBottom ml={16} style={{ fontWeight: 'bold', fontSize: '27px' }}>
                    Service Log
                </Typography>
                {id && (
                    <AddServiceLogNote onNoteAdded={fetchData} productConfigId={id} />
                )}
            </Box>

            <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" style={{ width: "10%", position: 'sticky', top: 0, backgroundColor: theme.palette.background.default, zIndex: 1 }}>User</TableCell>
                            <TableCell align="center" style={{ position: 'sticky', top: 0, backgroundColor: theme.palette.background.default, zIndex: 1 }}>Note</TableCell>
                            <TableCell align="center" style={{ position: 'sticky', top: 0, backgroundColor: theme.palette.background.default, zIndex: 1 }}>Date/Time</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{
                '&:hover': { cursor: 'pointer', backgroundColor: theme.palette.mode === 'light' ? '#F3F3F3' : '#1F1F1F' }}}>
                        {loading ? (
                            Array.from({ length: 4 }).map((_, index) => (
                                <TableRow key={index}>
                                    <TableCell align="center">
                                        <Skeleton animation="wave" variant="circular" width={24} height={24} />
                                    </TableCell>
                                    <TableCell align="center">
                                        <Skeleton animation="wave" variant="text" width={150} />
                                    </TableCell>
                                    <TableCell align="center">
                                        <Skeleton animation="wave" variant="text" width={100} />
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            serviceLogData.map((row, index) => (
                                <TableRow
                                    key={index}
                                    onDoubleClick={() => handleRowDoubleClick(row)}
                                    sx={{ '&:hover': { cursor: 'pointer' } }}
                                >
                                    <Tooltip title={row.username} placement="left">
                                        <TableCell align="center">
                                            <Avatar alt="Remy Sharp" src={frdarklogo} sx={{ width: 24, height: 24 }} />
                                        </TableCell>
                                    </Tooltip>
                                    {!id ? <TableCell align="center">{row.productConfigId}</TableCell> : null}
                                    <TableCell align="center">{row.note}</TableCell>
                                    <TableCell align="center">
                                        {offsetDate(new Date(row.logDate)).toLocaleString('en-US', {
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                        })}
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </Box>

            <Modal
                open={isEditModalOpen}
                onClose={handleCloseEditModal}
                closeAfterTransition
            >
                <Fade in={isEditModalOpen}>
                    <div>
                        <EditServiceLogNote note={noteToEdit} onUpdateNote={handleCloseEditModal} openSnackbar={openSnackbar} />
                    </div>
                </Fade>
            </Modal>

            <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
                <MuiAlert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                    Service log note saved!
                </MuiAlert>
            </Snackbar>
        </Box>
    );
}


