import { AuthenticatedTemplate, UnauthenticatedTemplate, } from "@azure/msal-react";
import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import DeviceInfoMainTable from "./DeviceInfoMainTable";

export function DeviceMainPage() {

    return (
        <>
            <AuthenticatedTemplate>
                <Grid item xs={12} sx={{ marginTop: '-2%', marginBottom: '-35px' }}>
                    <Box sx={{width: "100%"}}>
                        <DeviceInfoMainTable />
                    </Box>
                </Grid>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <Typography variant="h6">
                    <center>Please sign-in to see your profile information.</center>
                </Typography>
            </UnauthenticatedTemplate>
        </>
    );
}


