import React, { useEffect, useState } from 'react';
import { Button, Grid, Tab, Tabs, Typography } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import { systemsIntegrationAxios as axios } from "../../../utils/AxiosUtility";
import AddDevice from './AddDevice';
import EditRow from './EditRow';
import AddNozzleMaster from './AddNozzleMaster';
import DeleteDevice from './DeleteDevice';
import DeleteNozzleTab from './DeleteNozzleTab';

export default function MidDeviceSection({ id, prodConfigId, prodType }) {
  const [allDevices, setAllDevices] = useState([]);
  const [productConfigId, setProductConfigId] = useState(prodConfigId);
  const [productType, setProductType] = useState(prodType);
  const [nozzleProgrammingDataList, setNozzleProgrammingDataList] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [editRowData, setEditRowData] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [nozzleIds, setNozzleIds] = useState([]);
  const [dpId, setDpId] = useState(null);
  const [npId, setNpId] = useState(null);

  const handleModalOpen = () => setEditModalOpen(true);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const handleEditModalClose = () => { 
    setEditModalOpen(false);
    setSelectedRow(null); 
  };
  
  const resetOnClose = () => {
    setSelectedRow(null);
  }

  useEffect(() => {
    setProductType(prodType);
    setProductConfigId(prodConfigId);
  }, [id, prodConfigId, prodType]);

  const fetchDeviceInfo = async () => {
    setSelectedRow(null);
    setTabIndex(0);
    if (productConfigId) {
      try {
        const response = await axios.get(`api/DeviceInfo/getDeviceInfoFromPcId/${productConfigId}`);
        setAllDevices(Array.isArray(response.data) ? response.data : [response.data]);
      } catch (error) {
        console.error("Error retrieving all devices: ", error);
      }
    }
  };

  const checkTypeAndFetchNozzleInfo = async () => {
    setSelectedRow(null);
    if (prodType && productConfigId) {
      try {
        if (prodType === 'BSU' || prodType === 'CF') {
          const response = await axios.get(`api/NozzleMaster/nozzleMasterFromProductConfigId/${productConfigId}`);
          const nozzleData = response.data;
          const ids = nozzleData.map(item => item.nozzleId);
          setNozzleIds(ids);
          const nozzleProgrammingData = await Promise.all(nozzleData.map(async (nozzle) => {
            const programmingResponse = await axios.get(`api/NozzleProgramming/getAllNpFromNozzleId/${nozzle.nozzleId}`);
            return {
              name: nozzle.nozzleArrangementName,
              nozzleProgrammings: programmingResponse.data
            };
          }));
          setNozzleProgrammingDataList(nozzleProgrammingData);
        }
      } catch (error) {
        console.error("Error handling the nozzle info: ", error);
      }
    }
  };

  useEffect(() => {
    fetchDeviceInfo();
  }, [productConfigId]);

  useEffect(() => {
    checkTypeAndFetchNozzleInfo();
  }, [prodType, productConfigId]);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    setSelectedRow(null);
    setEditRowData(null);
  };

  const handleAddTab = () => {
    const newTabName = `Nozzle ${nozzleProgrammingDataList.length + 1}`;
    setNozzleProgrammingDataList([...nozzleProgrammingDataList, { name: newTabName, nozzleProgrammings: [] }]);
    setTabIndex(nozzleProgrammingDataList.length + 1); 
  };

  const handleUpdateTabIndex = () => {
    const newIndex = Math.max(0, nozzleProgrammingDataList.length - 1);
    setTabIndex(newIndex);
  };

  const commonColumns = [
    { accessorKey: 'deviceName', header: 'Name' },
    { accessorKey: 'deviceType', header: 'Type' },
    { accessorKey: 'macAddress', header: 'MAC Address' },
    { accessorKey: 'internalIpAddress', header: 'Internal IP Address' },
    { accessorKey: 'internalHttp', header: 'Internal HTTP' },
    { accessorKey: 'internalMod', header: 'Internal MOD' },
    { accessorKey: 'internalRtsp', header: 'Port RTSP' },
    { accessorKey: 'externalHttp', header: 'External HTTP' },
    { accessorKey: 'externalRtsp', header: 'External RTSP' },
    { accessorKey: 'externalMod', header: 'External MOD'},
  ];

  const additionalColumn = { accessorKey: 'sysMsgSerialNumber', header: 'S Number' };


  const getNozzleDataForTab = () => {
    if (tabIndex === 0) {
      return allDevices;
    }

    const selectedNozzle = nozzleProgrammingDataList[tabIndex - 1];
    
    if (selectedNozzle) {
      if (selectedNozzle.nozzleProgrammings.length > 0) {
        return selectedNozzle.nozzleProgrammings;
      }
      else {
        if (selectedNozzle) {
          return selectedNozzle.nozzleProgrammings;
        }
      }
    }
    return;
  };

  const handleRowClick = (row) => {
    if (selectedRow === row.original) {
      setDpId(null);
      setNpId(null);
      setSelectedRow(null);
      setEditRowData(null);
    } else {
      if (JSON.stringify(row.original.dpId)) {
        setDpId(JSON.stringify(row.original.dpId));
      }
      else if (JSON.stringify(row.original.npId)) {
        setNpId(JSON.stringify(row.original.npId));
      }
      setSelectedRow(row.original);
      setEditRowData(row.original);
    }
  };

  const handleRowDoubleClick = (row) => {
    setSelectedRow(row.original);
    setEditRowData(row.original);
    handleModalOpen();
  };

  const handleEditButtonClick = () => {
    setEditModalOpen(true);
  };

  const renderTemplate = () => {
    const dynamicColumns = tabIndex > 0 
      ? [...commonColumns, additionalColumn]
      : commonColumns;

    switch (productType) {
      case 'BSU':
      case 'CF':
        return (
          <>
            <Tabs value={tabIndex} onChange={handleTabChange} aria-label={`${productType} Tabs`} variant='scrollable' scrollButtons='auto'>
              <Tab label="General Devices" />
              {nozzleProgrammingDataList.map((item, index) => (
                <Tab key={index} label={item.name} />
              ))}
              <AddNozzleMaster pcId={prodConfigId} onAddNozzle={handleAddTab} onSave={checkTypeAndFetchNozzleInfo} />
            </Tabs>
            <MaterialReactTable
              columns={dynamicColumns}
              data={getNozzleDataForTab() ?? []}
              enableStickyHeader={true}
              muiTableBodyRowProps={({ row }) => ({
                onClick: () => handleRowClick(row),
                onDoubleClick: () => handleRowDoubleClick(row),
                sx: {
                  cursor: 'pointer',
                  backgroundColor: selectedRow === row.original ? 'rgba(0, 0, 255, 0.1)' : 'inherit',
                },
              })}
              muiTableContainerProps={{
                sx: { maxHeight: '40vh', overflow: 'auto' },
              }}
            />
          </>
        );
      case 'OW':
      case 'ED':
        return (
          <>
            <Tabs value={tabIndex} onChange={handleTabChange} aria-label={`${productType} Tabs`} variant='scrollable' scrollButtons='auto'>
              <Tab label="General Devices" />
            </Tabs>
            <MaterialReactTable
              columns={commonColumns}
              data={getNozzleDataForTab() ?? []}
              enableStickyHeader={true}
              muiTableBodyRowProps={({ row }) => ({
                onClick: () => handleRowClick(row),
                onDoubleClick: () => handleRowDoubleClick(row),
                sx: {
                  cursor: 'pointer',
                  backgroundColor: selectedRow === row.original ? 'rgba(0, 0, 255, 0.1)' : 'inherit',
                },
              })}
              muiTableContainerProps={{
                sx: { maxHeight: '40vh', overflow: 'auto' },
              }}
            />
          </>
        );
      default:
        return (
          <>
            <Tabs value={tabIndex} onChange={handleTabChange} aria-label={`${productType} Tabs`} variant='scrollable' scrollButtons='auto'>
              <Tab label="General Devices" />
              {nozzleProgrammingDataList.map((item, index) => (
                <Tab key={index} label={item.name} />
              ))}
              <AddNozzleMaster pcId={prodConfigId} onAddNozzle={handleAddTab} onSave={checkTypeAndFetchNozzleInfo} />
            </Tabs>
            <MaterialReactTable
              columns={commonColumns}
              data={getNozzleDataForTab() ?? []}
              enableStickyHeader={true}
              muiTableBodyRowProps={({ row }) => ({
                onClick: () => handleRowClick(row),
                onDoubleClick: () => handleRowDoubleClick(row),
                sx: {
                  cursor: 'pointer',
                  backgroundColor: selectedRow === row.original ? 'rgba(0, 0, 255, 0.1)' : 'inherit',
                },
              })}
              muiTableContainerProps={{
                sx: { maxHeight: '40vh', overflow: 'auto' },
              }}
            />
          </>
        );
    }
  };

  return (
    <Grid
      container
      minWidth={'100%'}
      sx={{ marginBottom: '1%', marginTop: '1%', padding: '5% 0', width: '100%', height: '100%', overflow: 'auto' }}
    >
      <Grid container spacing={2} justifyContent="flex-end" alignItems='center'>
        {selectedRow !== null && (
          <Grid item>
            <DeleteDevice
              devId={dpId}
              nozId={npId}
              onDeviceDeleted={fetchDeviceInfo}
              onNozzleDeleted={checkTypeAndFetchNozzleInfo}
              reset={resetOnClose}
              selectedRow={selectedRow}
            />
          </Grid>
        )}
        {selectedRow !== null && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleModalOpen}
            >
              Edit Selected Device
            </Button>
          </Grid>
        )}
        <Grid item>
          <AddDevice
            productConfigId={prodConfigId}
            nozIds={nozzleIds}
            tabInd={tabIndex}
            allDevice={allDevices}
            onDeviceAdded={fetchDeviceInfo}
            onNozzleAdded={checkTypeAndFetchNozzleInfo}
            reset={resetOnClose}
          />
        </Grid>
        {tabIndex > 0 && (
          <Grid item>
            <DeleteNozzleTab 
              nozzleIds={nozzleIds} 
              tabIndex={tabIndex} 
              onTabDeleted={checkTypeAndFetchNozzleInfo} 
              onUpdateTabIndex={handleUpdateTabIndex} 
              nozzleTabName={nozzleProgrammingDataList[tabIndex - 1]?.name} 
              modalClose={() => setSelectedRow(null)}
            />
          </Grid>
        )}
      </Grid>
      {editRowData && (
        <EditRow
          open={editModalOpen}
          onClose={handleEditModalClose}
          rowInfo={editRowData}
          onDeviceRowUpdated={fetchDeviceInfo}
          onNozzleRowUpdated={checkTypeAndFetchNozzleInfo}
          tabInd={tabIndex}
        />
      )}
      <Grid item xs={12} flexGrow={1}>
        {renderTemplate()}
      </Grid>
    </Grid>
  );
}



