import React, {useState, useEffect} from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  useTheme
} from '@mui/material';
import { systemsIntegrationAxios as axios } from "../../../utils/AxiosUtility";
import EditAirgas from './EditAirgas';
import AddAirgas from './AddAirgas';

export default function AirgasTable(pcId) {
  const borderStyle = { border: null };
  const externalBorderStyle = {borderTop: '2px solid #BEBEBE', borderRight: '2px solid #BEBEBE', borderBottom: '2px solid #BEBEBE', borderLeft: '2px solid #BEBEBE'};
  const [airgasData, setAirgasData] = useState(null);
  const [prodConfigId, setProdConfigId] = useState(pcId.pcId);
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return ''; 
    const cleaned = ('' + phoneNumber).replace(/\D/g, ''); 
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`; 
    }
    return phoneNumber; 
  };

  useEffect(() => {
    setProdConfigId(pcId.pcId);
  }, [pcId]);

  const fetchData = () => {
    const endpoint = `api/AirGasSupplier/airGasInfoFromPcId/${prodConfigId}`;
    axios.get(endpoint)
      .then((response) => {
        if (response.data && Object.keys(response.data).length > 0) {
          setAirgasData(response.data);
        } else {
          console.log("No data available for the given Product Config ID.");
          setAirgasData(null); 
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("Error retrieving Airgas info: ", error);
        setAirgasData(null);
      });
  };

  useEffect(() => {
    fetchData();
  }, [prodConfigId]);

  return (
    <TableContainer component={Paper}>
      <Table
        style={{ borderCollapse: 'collapse', ...externalBorderStyle, minHeight: '350px', maxHeight: '350px'}} 
        sx={{
          '& .MuiTableCell-root': {
            padding: '4px', 
            fontSize: '0.875rem',
            height: '32px',
            backgroundColor: 'inherit'
          },
          '& .MuiTableCell-head': {
            fontWeight: 'bold',
            backgroundColor: 'inherit',
            height: '65px'
          },
          '& .MuiTableRow-root': {
            height: '28px', 
          },
          '& th, & td': {
            whiteSpace: 'nowrap',
            textAlign: 'center', 
          },
          '&:hover': { backgroundColor: theme.palette.mode === 'light' ? '#F3F3F3' : '#252525' }
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell colSpan={3} align="center" style={{ ...borderStyle, fontWeight: 'bold' }}>
              <Box display="inline-flex" alignItems="center" justifyContent="space-around" width="auto" marginLeft='15%' paddingLeft='10%'>
                <Typography variant='h6' gutterBottom style={{ fontWeight: 'bold', fontSize: '27px' }}>
                  Airgas
                </Typography>
                {!isLoading && (
                  <Box ml={2} style={{display: 'flex', flexDirection: 'row', paddingLeft: '25%'}}>
                    {airgasData ? (
                      <EditAirgas agData={airgasData} onSave={fetchData} />
                    ) : (
                      <AddAirgas pcId={prodConfigId} onSave={fetchData} />
                    )}
                  </Box>
                )}
              </Box>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell style={{ ...borderStyle, width: '25%' }}>Account:</TableCell>
            <TableCell colSpan={2} align="center" style={{ ...borderStyle, width: '75%' }}>
              {airgasData?.accountNo}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ ...borderStyle, width: '25%' }}></TableCell>
            <TableCell colSpan={2} align="center" style={{ ...borderStyle, width: '75%' }}></TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ ...borderStyle, width: '25%' }}>Address:</TableCell>
            <TableCell colSpan={2} align="center" style={{ ...borderStyle, width: '75%' }}>
              {airgasData?.address1 || airgasData?.address2 ? `${airgasData.address1 || ''}${airgasData.address2 ? `, ${airgasData.address2}` : ''}` : ''}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ ...borderStyle, width: '25%' }}></TableCell>
            <TableCell colSpan={2} align="center" style={{ ...borderStyle, width: '75%' }}></TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ ...borderStyle, width: '25%' }}>City/State:</TableCell>
            <TableCell colSpan={2} align="center" style={{ ...borderStyle, width: '75%' }}>
              {airgasData?.city || airgasData?.st || airgasData?.country ? 
                `${airgasData.city || ''}${airgasData.st ? `, ${airgasData.st}` : ''}${airgasData.country ? `, ${airgasData.country}` : ''}` 
                : ''}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ ...borderStyle, width: '25%' }}></TableCell>
            <TableCell colSpan={2} align="center" style={{ ...borderStyle, width: '75%' }}></TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ ...borderStyle, width: '25%' }}>Phone:</TableCell>
            <TableCell colSpan={2} align="center" style={{ ...borderStyle, width: '75%' }}>
              {formatPhoneNumber(airgasData?.phoneNo) || ''}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

