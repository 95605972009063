import React, { useEffect, useState } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, useTheme } from '@mui/material';
import { systemsIntegrationAxios as axios } from "../../../utils/AxiosUtility";
import EditNetworkInfo from './EditNetworkInfo';

export default function NetworkInfoTable({ id }) {
  const [rowId, setRowId] = useState(id);
  const [networkInfo, setNetworkInfo] = useState({});
  const [networkType, setNetworkType] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();

 
  const containerBorderStyle = { border: '2px solid #BEBEBE' };

  const fetchData = async () => {
    try {
      const networkInfoEndpoint = `api/NetworkInfo/networkInfoFromDeviceId/${rowId}`;
      const response = await axios.get(networkInfoEndpoint);
      setNetworkInfo(response.data);
      setNetworkType(response.data.networkType);
      setIsLoading(false);
    }
    catch (error) {
      setIsLoading(false);
      console.error("Error retrieving data", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [rowId]);

  return (
    <TableContainer component={Paper} style={containerBorderStyle}>
      <Table
        style={{ borderCollapse: 'collapse', minHeight: '500px', maxHeight: '500px' }} 
        sx={{
          '& .MuiTableCell-root': {
            padding: '4px', 
            fontSize: '0.875rem', 
            backgroundColor: 'inherit',
          },
          '& .MuiTableCell-head': {
            fontWeight: 'bold',
            backgroundColor: 'inherit',
          },
          '& .MuiTableRow-root': {
            height: '24px',
          },
          '& th, & td': {
            whiteSpace: 'nowrap', 
            textAlign: 'center', 
          },
          '&:hover': { backgroundColor: theme.palette.mode === 'light' ? '#F3F3F3' : '#252525' },
          '& .no-border': {
            border: 'none', 
          },
          '& .bottom-border-only': {
            borderTop: 'none',
            borderBottom: theme.palette.mode === 'light' ? '1px solid #D2D2D2' : '1px solid #4D4D4D'
          }
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell colSpan={3} align="center" style={{ fontWeight: 'bold' }}>
              <Box display="inline-flex" alignItems="center" justifyContent="space-around" width="auto" marginLeft='15%'>
                <Typography variant='h6' gutterBottom style={{ fontWeight: 'bold', fontSize: '27px' }}>
                  Network Information
                </Typography>
                {!isLoading && networkInfo && (
                  <Box ml={2}>
                    <EditNetworkInfo netInfo={networkInfo} netType={networkType} onSave={fetchData} />
                  </Box>
                )}
              </Box>
            </TableCell>
          </TableRow>
        </TableHead>
        
        <TableBody>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="center" style={{ fontWeight: 'bold' }}>WAN</TableCell>
            <TableCell align="center" style={{ fontWeight: 'bold' }}>Cellular</TableCell>
          </TableRow>
        </TableBody>
        
        <TableBody>
          <TableRow>
            <TableCell>IP:</TableCell>
            <TableCell colSpan={1} align="center">{networkInfo.wanIpAddress}</TableCell>
            <TableCell colSpan={1} align="center">{networkInfo.cellIpAddress}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Subnet Mask:</TableCell>
            <TableCell colSpan={1} align="center">{networkInfo.wanSubnetMask}</TableCell>
            <TableCell colSpan={1} align="center">{networkInfo.cellSubnetMask}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Gateway:</TableCell>
            <TableCell colSpan={1} align="center">{networkInfo.wanGateway}</TableCell>
            <TableCell colSpan={1} align="center">{networkInfo.cellGateway}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>DNS 1:</TableCell>
            <TableCell colSpan={1} align="center">{networkInfo.wanDns1}</TableCell>
            <TableCell colSpan={1} align="center">{networkInfo.cellDns1}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>DNS 2:</TableCell>
            <TableCell colSpan={1} align="center">{networkInfo.wanDns2}</TableCell>
            <TableCell colSpan={1} align="center">{networkInfo.cellDns2}</TableCell>
          </TableRow>
          <TableRow className="no-border">
            <TableCell colSpan={3} className="no-border"></TableCell>
          </TableRow>
          <TableRow className="bottom-border-only">
            <TableCell colSpan={3} className="no-border"></TableCell>
          </TableRow>
          <TableRow className="bottom-border-only">
            <TableCell colSpan={1} style={{ fontWeight: 'bold' }}>SIM Brand</TableCell>
            <TableCell colSpan={1} style={{ fontWeight: 'bold' }}>SIM ID</TableCell>
            <TableCell colSpan={1} style={{ fontWeight: 'bold' }}>SIM IP</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>SIM 1: {networkInfo.sim1Brand}</TableCell>
            <TableCell colSpan={1} align="center">{networkInfo.sim1Id}</TableCell>
            <TableCell colSpan={1} align="center">{networkInfo.simIp}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>SIM 2: {networkInfo.sim2Brand}</TableCell>
            <TableCell colSpan={1} align="center">{networkInfo.sim2Id}</TableCell>
            <TableCell colSpan={1} align="center">{networkInfo.sim2Ip}</TableCell>
          </TableRow>
          <TableRow className="no-border">
            <TableCell colSpan={3} className="no-border"></TableCell>
          </TableRow>
          <TableRow className="bottom-border-only">
            <TableCell colSpan={3} className="no-border"></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Router:</TableCell>
            <TableCell colSpan={2} align="center">{networkInfo.router}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>DDNS:</TableCell>
            <TableCell colSpan={2} align="center">{networkInfo.ddns}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>IMEI:</TableCell>
            <TableCell colSpan={2} align="center">{networkInfo.imei}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}


